import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { io } from 'socket.io-client';
import {
  styled,
  Button,
  Container,
  Box,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import NewTrackIcon from '@mui/icons-material/AddRoad';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import CancelDialogIcon from '@mui/icons-material/RemoveRoadOutlined';
import InfoDialogIcon from '@mui/icons-material/InfoOutlined';
import AdjustClockIcon from '@mui/icons-material/ManageHistoryOutlined';

import NavBar from './Components/AppBar';
import SelectItem from './Components/basics/Select';
import AlertItem from './Components/basics/ActionAlert';
import DialogItem from './Components/basics/DialogConfirmAction';
import COLORS from './Colors';

import tiempos from './utils/tiempos-canje';
import SECRETS from './secrets';

const socket = io(SECRETS.SOCKETURL);

const flexCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const panelBorder = {
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: `${COLORS.NEUTRAL400}`,
};

const styledTimePicker = {
  fontSize: '18px',
  border: '0',
  borderRadius: '4px',
  backgroundColor: `${COLORS.GRAY200}`,
  padding: '0.5rem 0.625rem',
  width: 'calc(100% - 1.25rem)',
  boxShadow: 'rgb(0 0 0 / 10%) 0px 2px 2px inset',
};

const timeLabelStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  fontSize: '16px',
  color: '#777',
};

const minutesButtonStyle = {
  width: '5rem',
  borderWidth: '2px',
  borderStyle: 'solid',
  padding: '0.25rem 0.5rem',
  fontSize: 16,
  textTransform: 'capitalize',
  transition: 'all 0.4s ease-out',
};

const minutesButtonHover = {
  transition: 'all 0.4s ease-in',
  borderWidth: '2px',
  borderStyle: 'solid',
};

const cardsContainerStyle = {
  margin: '1.5rem',
};

const cardStyle = {
  boxShadow: '0 1px 3px hsla(0, 0%, 0%, .2)',
  borderRadius: '2px',
  margin: '6px 0',
  padding: '12px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

const loadingContainerStyle = {
  height: '100vh',
  width: '100vw',
  backgroundColor: 'rgba(10,10,10,0.4)',
  position: 'fixed',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backdropFilter: 'blur(3px)',
};

const cancelCard = {
  ...cardStyle,
  boxShadow: '0 1px 3px hsla(0, 0%, 0%, .3)',
  backgroundColor: COLORS.GRAY300,
  color: COLORS.GRAY900,
};

const cancelBadge = {
  height: '60px',
  fontSize: '18px',
  margin: '12px 0px',
  border: '0px',
  borderRadius: '3px',
  minWidth: '90px',
  color: COLORS.RED700,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxShadow: 'none',
};

const StyledContainer = styled(Container)({
  display: 'flex',
  flexFlow: 'column',
  height: '100%',
  width: '100%',
  position: 'absolute',
});

const StSelectHourBox = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  paddingRight: '0',
  [theme.breakpoints.up('sm')]: { paddingRight: '0.5rem' },
  [theme.breakpoints.up('md')]: { paddingLeft: '0.5rem' },
}));

const StPanelGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  marginTop: '1.5rem',
  [theme.breakpoints.up('sm')]: {
    paddingLeft: '0.5rem',
  },
}));

const StHeaderMinutesBox = styled(Box)(() => ({
  ...flexCenter,
  width: '5.5rem',
  height: 'calc(100% - 4px)',
}));
const StHeaderInnerBox = styled(Box)(() => ({
  ...flexCenter,
  ...panelBorder,
  width: '5.5rem',
  height: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: `${COLORS.NEUTRAL200}`,
  color: `${COLORS.NEUTRAL600}`,
  fontSize: 14,
  fontWeight: 'bold',
  borderRadius: '0.375rem 0 0 0.375rem',
  padding: '0.25rem 0.375rem',
}));

const StBodyMinutesBox = styled(Box)(() => ({
  ...flexCenter,
  width: 'calc(100% - 5.5rem)',
  height: 'calc(100% - 4px)',
}));
const StBodyInnerBox = styled(Box)(() => ({
  ...panelBorder,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
  borderLeft: 0,
  borderRadius: '0 0.375rem 0.375rem 0',
  padding: '0.25rem 0',
}));

const StWrapperButtonsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '47.5%',
  [theme.breakpoints.up('md')]: {
    width: '45%',
  },
}));

const StMinutesPinkButton = styled(Button)(() => ({
  ...minutesButtonStyle,
  backgroundColor: `${COLORS.PINK50}`,
  borderColor: `${COLORS.PINK700}`,
  color: `${COLORS.PINK700}`,
  '&:hover': {
    ...minutesButtonHover,
    backgroundColor: `${COLORS.PINK100}`,
    borderColor: `${COLORS.PINK800}`,
    color: `${COLORS.PINK800}`,
  },
}));

const StMinutesEmeraldButton = styled(Button)(() => ({
  ...minutesButtonStyle,
  backgroundColor: `${COLORS.EMERALD50}`,
  borderColor: `${COLORS.EMERALD700}`,
  color: `${COLORS.EMERALD700}`,
  '&:hover': {
    ...minutesButtonHover,
    backgroundColor: `${COLORS.EMERALD100}`,
    borderColor: `${COLORS.EMERALD800}`,
    color: `${COLORS.EMERALD800}`,
  },
}));

const StContainerNewTrackGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  [theme.breakpoints.up('md')]: {
    paddingLeft: '0.5rem',
  },
}));

const StNewTrackButton = styled(Button)(() => ({
  fontSize: '1.125rem',
  textTransform: 'uppercase',
  backgroundColor: `${COLORS.SLATE200}`,
  color: `${COLORS.SLATE600}`,
  border: `2px solid ${COLORS.SLATE600}`,
  borderRadius: '0.375rem',
  marginTop: '2rem',
  transition: 'all 0.4s ease-out',
  '&:hover': {
    backgroundColor: `${COLORS.SLATE300}`,
    color: `${COLORS.SLATE800}`,
    border: `2px solid ${COLORS.SLATE800}`,
    transition: 'all 0.4s ease-in',
  },
  '& .MuiButton-startIcon>*:nth-of-type(1)': {
    fontSize: 28,
  },
}));

const CancelStyledButton = styled(Button)(() => ({
  textTransform: 'capitalize',
  fontSize: 18,
  transition: 'all 0.4s ease-out',
  '&:hover': {
    backgroundColor: COLORS.RED100,
    transition: 'all 0.4s ease-in',
  },
}));

const StyledButtonCloseBadge = styled(Button)(() => ({
  borderColor: COLORS.RED700,
  borderStyle: 'solid',
  borderWidth: '2px',
  color: COLORS.RED700,
  textTransform: 'capitalize',
  transition: 'all 0.4s ease-out',
  '&:hover': {
    borderColor: COLORS.RED900,
    borderWidth: '2px',
    color: COLORS.RED900,
    transition: 'all 0.4s ease-in',
  },
}));

const twoDigits = (number) => {
  if (number < 10) {
    return `0${number}`;
  }
  return number;
};

const MainScreen = ({
  id,
  token,
  setId,
  setToken,
}) => {
  const [isLogged, setIsLogged] = useState(true);
  const [operadores, setOperadores] = useState([]);
  const [formOperador, setFormOperador] = useState('');
  const [rutas, setRutas] = useState([]);
  const [formRuta, setFormRuta] = useState('');
  const [selectedRutaObj, setSelectedRutaObj] = useState({});
  const [unidades, setUnidades] = useState([]);
  const [formUnidad, setFormUnidad] = useState('');
  const [recorridos, setRecorridos] = useState([]);
  const [horaInicio, setHoraInicio] = useState('00:00');
  const [horaRegreso, setHoraRegreso] = useState('00:00');
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ open: false, severity: 'success', message: '' });
  const [dialogObj, setDialogObj] = useState({
    open: false,
    infoPanel: {},
    dialogId: '',
    titleText: '',
    iconTitle: false,
    cancelButton: false,
  });

  const setClockNow = () => {
    const date = new Date();
    setHoraInicio(`${twoDigits(date.getHours())}:${twoDigits(date.getMinutes())}`);
  };

  const clearFields = () => {
    setFormOperador('');
    setFormUnidad('');
  };

  useEffect(() => {
    if (id <= 0 && token.length === 0) {
      setIsLogged(false);
    }
  }, [id, token]);
  useEffect(() => {
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .get('/controloperador')
      .then((response) => {
        setOperadores(response.data);
      })
      .catch((e) => {
        setAlert({ severity: 'error', message: 'Sin conexión a internet', open: true });
        console.error(e);
        if (e.response.status === 401) {
          setId(0);
          setToken('');
        }
      });
  }, []);
  useEffect(() => {
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .get('/controlruta')
      .then((response) => {
        setRutas(response.data);
      })
      .catch((e) => {
        setAlert({ severity: 'error', message: 'Sin conexión a internet', open: true });
        console.error(e);
        if (e.response.status === 401) {
          setId(0);
          setToken('');
        }
      });
  }, []);

  useEffect(() => {
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .get('/unidades/organizacion')
      .then((response) => {
        setUnidades([...response.data]);
      })
      .catch((e) => {
        setAlert({ severity: 'error', message: 'Sin conexión a internet', open: true });
        console.error(e);
        if (e.response.status === 401) {
          setId(0);
          setToken('');
        }
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    const date = new Date();
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .get(`/controlrecorrido/byorganizacion/${date.getFullYear()}-${twoDigits(date.getMonth() + 1)}-${twoDigits(date.getDate())}`)
      .then((response) => {
        setLoading(false);
        const arrayTracks = response.data
          .filter((a) => !a.terminado && !a.cancelado)
          .map((recorrido) => ({
            ...recorrido,
            currentCancel: false,
          }));
        setRecorridos(arrayTracks);
        return arrayTracks;
      })
      .catch((e) => {
        setLoading(false);
        setAlert({ severity: 'error', message: 'Sin conexión a internet', open: true });
        console.error(e);
        if (e.response.status === 401) {
          setId(0);
          setToken('');
        }
      });
  }, []);

  useEffect(() => {
    setClockNow();
  }, []);

  useEffect(() => {
    const setTracks = ({ status, recorrido }) => {
      setRecorridos((rec) => {
        const clone = [...rec];
        let index = -1;
        const { idControlOperador, idUnidad } = recorrido;
        switch (status) {
          case 'cancelado':
          case 'terminado':
            index = clone.findIndex((c) => c.id === recorrido.id);
            if (index >= 0) {
              clone.splice(index, 1);
            }
            return clone;
          case 'nuevo':
            setOperadores((old) => {
              const oldOperadores = [...old];
              const oldIndex = oldOperadores.findIndex((oo) => oo.id === idControlOperador);
              if (oldIndex >= 0) {
                oldOperadores[oldIndex] = {
                  ...oldOperadores[oldIndex],
                  idUnidad,
                };
              }
              return oldOperadores;
            });
            index = clone.findIndex((c) => c.id === recorrido.id);
            if (index === -1) {
              clone.push({ ...recorrido });
            }
            return clone;
          default:
        }
        return clone;
      });
    };
    socket.on('status', setTracks);
    return () => {
      socket.off('status', setTracks);
    };
  }, [unidades, recorridos]);

  // const isDriverInLiveTrack = (driver) => (
  //   recorridos.some((track) => track.idControlOperador === driver.id));
  // const driversArray = (
  //   operadores.filter((driver) => !isDriverInLiveTrack(driver) || formRuta === 16));
  const driversArray = operadores;

  const unitsArray = (
    unidades.filter((unit) => !unit.eliminado)
    // {
    //   if (!unit.eliminado && formRuta === 16) {
    //     return true;
    //   }
    //   const recorridoIndex = recorridos.findIndex((r) => r.imei === unit.imei);
    //   return !unit.eliminado && recorridoIndex === -1;
    // })
  );

  const addMinutes = (amount) => {
    setHoraInicio((horaI) => {
      const tiempo = horaI.split(':');
      const newTime = new Date(`2022-12-01T${tiempo[0]}:${tiempo[1]}:00Z`);
      newTime.setMinutes(parseInt(newTime.getMinutes(), 10) + amount);
      const timeString = `${twoDigits(newTime.getUTCHours())}:${twoDigits(newTime.getUTCMinutes())}`;
      if (selectedRutaObj.idRutaNext) {
        const tiempoRegreso = tiempos[timeString];
        if (tiempoRegreso) {
          setHoraRegreso(tiempoRegreso);
        }
      }
      return timeString;
    });
  };

  const addNewTrack = () => {
    setDialogObj((current) => ({ ...current, open: false }));
    setLoading(true);
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    const {
      id: idControlOperador,
      nombre: nombreOperador,
    } = operadores.find((op) => op.id === parseInt(formOperador, 10));
    const {
      id: idControlRuta,
      nombre: nombreRuta,
    } = rutas.find((r) => r.id === parseInt(formRuta, 10));
    const {
      idUnidad,
      imei,
    } = unidades.find((u) => u.idUnidad === parseInt(formUnidad, 10));

    const initHour = new Date();
    const [hora, minutos] = horaInicio.split(':');
    initHour.setHours(parseInt(hora, 10));
    initHour.setMinutes(parseInt(minutos, 10));
    let data = {
      idControlOperador,
      idControlRuta,
      idUnidad,
      nombreRuta,
      nombreOperador,
      imei,
      inicio: `${initHour.getUTCFullYear()}-${twoDigits(initHour.getUTCMonth() + 1)}-${twoDigits(initHour.getUTCDate())}T${twoDigits(initHour.getUTCHours())}:${twoDigits(initHour.getUTCMinutes())}:00Z`,
    };
    if (selectedRutaObj.idRutaNext) {
      const returnHour = new Date();
      const [horaR, minutosR] = horaRegreso.split(':');
      returnHour.setHours(parseInt(horaR, 10));
      returnHour.setMinutes(parseInt(minutosR, 10));
      data = {
        ...data,
        horaRegreso: `${returnHour.getUTCFullYear()}-${twoDigits(returnHour.getUTCMonth() + 1)}-${twoDigits(returnHour.getUTCDate())}T${twoDigits(returnHour.getUTCHours())}:${twoDigits(returnHour.getUTCMinutes())}:00Z`,
      };
    }
    axios
      .post('/controlrecorrido/newv2', data)
      .then((response) => {
        const clone = [...recorridos];
        clone.push(response.data);
        setRecorridos(clone);
        setLoading(false);
        clearFields();
        addMinutes(5);

        setOperadores((currentOperadores) => {
          const operadorIndex = currentOperadores
            .findIndex((op) => op.id === parseInt(formOperador, 10));
          const operadoresClone = currentOperadores.map((op) => {
            if (op.idUnidad === parseInt(formUnidad, 10)) {
              return {
                ...op,
                idUnidad: 0,
              };
            }
            return op;
          });
          operadoresClone[operadorIndex] = {
            ...operadoresClone[operadorIndex],
            idUnidad: parseInt(formUnidad, 10),
          };
          return operadoresClone;
        });
        setAlert({ severity: 'success', message: 'Nuevo recorrido creado', open: true });
      })
      .catch((e) => {
        setLoading(false);
        setAlert({ severity: 'error', message: 'Sin conexión a internet', open: true });
        console.error(e);
        if (e.response.status === 401) {
          setId(0);
          setToken('');
        }
      });
  };

  const openModalDiff = () => {
    if (formOperador === '' || formRuta === '' || formUnidad === '') {
      setAlert({ severity: 'error', message: 'Todos los campos son obligatorios', open: true });
      return;
    }
    const today = new Date();
    const initHour = new Date();
    const [hora, minutos] = horaInicio.split(':');
    initHour.setHours(parseInt(hora, 10));
    initHour.setMinutes(parseInt(minutos, 10));
    const diffTime = Math.abs(initHour - today);
    const diffMins = Math.ceil(diffTime / (1000 * 60));
    if (diffMins > 20) {
      setDialogObj({
        open: true,
        infoPanel: {},
        dialogId: 'over-twenty-minutes',
        titleText: 'Registrar recorrido',
        iconTitle: <InfoDialogIcon color="info" sx={{ mr: 1 }} />,
        cancelButton: true,
      });
    } else {
      addNewTrack();
    }
  };

  const cancelar = (recorridoId) => {
    const selectedTrackObj = recorridos.find((item) => item.id === recorridoId);
    setDialogObj({
      open: true,
      infoPanel: {
        trackId: recorridoId,
        track: selectedTrackObj.nombreRuta,
        unidad: unidades.find((u) => `${u.imei}` === `${selectedTrackObj.imei}`)?.unidad ?? '',
        beginTrack: selectedTrackObj.inicio,
        driver: selectedTrackObj.nombreOperador,
      },
      dialogId: 'cancel-track',
      titleText: 'Recorrido a cancelar',
      iconTitle: <CancelDialogIcon color="error" sx={{ mr: 1 }} />,
      cancelButton: false,
    });
  };

  const confirmCancelTrack = (recorridoId) => {
    setDialogObj({ open: false });
    setLoading(true);
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .patch(`/controlrecorrido/${recorridoId}`, { cancelado: true })
      .then(() => {
        const clone = [...recorridos];
        const index = clone.findIndex((c) => c.id === recorridoId);
        setLoading(false);
        if (index >= 0) {
          clone[index].currentCancel = true;
          clone[index].cancelado = true;
          setRecorridos(clone);
          socket.emit('status', {
            code: SECRETS.SOCKETCODE,
            status: 'cancelado',
            recorrido: { id: recorridoId },
          });
          setAlert({ severity: 'success', message: 'Recorrido cancelado', open: true });
        }
      })
      .catch((e) => {
        setLoading(false);
        setAlert({ severity: 'error', message: 'Sin conexión a internet', open: true });
        console.error(e);
        if (e.response.status === 401) {
          setId(0);
          setToken('');
        }
      });
  };

  const handleOperadores = (e) => {
    setFormOperador(e.target.value);
  };
  const handleRuta = (e) => {
    setFormRuta(e.target.value);
    setSelectedRutaObj(rutas.find((r) => r.id === parseInt(e.target.value, 10)));
  };

  const handleUnidad = (e) => {
    setFormUnidad(e.target.value);
    const operador = operadores.find((op) => op.idUnidad === parseInt(e.target.value, 10));
    setFormOperador(`${operador.id}`);
  };

  const handleCloseAlert = () => setAlert((currentValue) => ({ ...currentValue, open: false }));

  const logout = () => {
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios.post('/logout', { token })
      .catch((reason) => {
        console.error(`cerrarSesion method App Error:: ${reason}`);
      });
    setId(0);
    setToken('');
  };

  const closeCanceledBadge = (trackId) => {
    const clone = [...recorridos];
    const index = clone.findIndex((c) => c.id === trackId);
    if (index >= 0) {
      clone[index].currentCancel = false;
      setRecorridos(clone);
    }
  };

  const handleTime = (e) => {
    setHoraInicio(e.target.value);
    if (selectedRutaObj.idRutaNext) {
      const tiempoRegreso = tiempos[e.target.value];
      if (tiempoRegreso) {
        setHoraRegreso(tiempoRegreso);
      }
    }
  };

  return (
    <StyledContainer disableGutters maxWidth={false}>
      {!isLogged && <Navigate to="/" />}
      <NavBar
        liftingLogout={logout}
      />
      <AlertItem
        stateAlert={alert}
        liftingCloseAlert={handleCloseAlert}
      />
      <DialogItem
        dialogObj={dialogObj}
        addNewTrack={addNewTrack}
        confirmCancelTrack={confirmCancelTrack}
        handleClose={() => setDialogObj({ open: false })}
      />
      <Grid container spacing={0} sx={{ mt: 2, px: 2 }}>
        <SelectItem
          typeSelect="unit"
          stateSelect={formUnidad}
          handleSelect={handleUnidad}
          arrayItems={unitsArray}
        />
        <SelectItem
          typeSelect="track"
          stateSelect={formRuta}
          handleSelect={handleRuta}
          arrayItems={rutas}
        />
        <SelectItem
          typeSelect="driver"
          stateSelect={formOperador}
          handleSelect={handleOperadores}
          arrayItems={driversArray.filter((d) => d.activo)}
        />
        <Grid xs={12} sm={5} md={3} lg={4} sx={{ mt: '1.5rem' }}>
          <StSelectHourBox>
            <label htmlFor="inicio-id" style={timeLabelStyle}>
              Hora de salida
              <input
                type="time-local"
                name="inicio"
                id="inicio-id"
                value={horaInicio}
                onChange={handleTime}
                style={styledTimePicker}
              />
            </label>
          </StSelectHourBox>
        </Grid>
        <StPanelGrid xs={12} sm={7} md={6} lg={4}>
          <StHeaderMinutesBox>
            <StHeaderInnerBox>
              <Typography variant="h6" component="div" sx={{ fontSize: '1rem' }}>
                Ajustar
              </Typography>
              <Box sx={{ ...flexCenter, justifyContent: 'flex-start' }}>
                <Typography variant="h6" component="div" sx={{ fontSize: '1rem' }}>
                  hora
                </Typography>
                <AdjustClockIcon sx={{ ml: 0.5, display: 'flex', alignSelf: 'center' }} />
              </Box>
            </StHeaderInnerBox>
          </StHeaderMinutesBox>
          <StBodyMinutesBox>
            <StBodyInnerBox>
              <StWrapperButtonsBox>
                <StMinutesPinkButton variant="outlined" onClick={() => addMinutes(-1)}>-1min</StMinutesPinkButton>
                <StMinutesPinkButton variant="outlined" onClick={() => addMinutes(-5)} sx={{ mt: '0.5rem' }}>-5min</StMinutesPinkButton>
              </StWrapperButtonsBox>
              <StWrapperButtonsBox>
                <StMinutesEmeraldButton variant="outlined" onClick={() => addMinutes(1)}>+1min</StMinutesEmeraldButton>
                <StMinutesEmeraldButton variant="outlined" onClick={() => addMinutes(+5)} sx={{ mt: '0.5rem' }}>+5min</StMinutesEmeraldButton>
              </StWrapperButtonsBox>
            </StBodyInnerBox>
          </StBodyMinutesBox>
        </StPanelGrid>
        {selectedRutaObj.idRutaNext && (
          <Grid xs={12} sm={5} md={3} lg={4} sx={{ mt: '1.5rem' }}>
            <StSelectHourBox>
              <label htmlFor="regreso-id" style={timeLabelStyle}>
                Hora de regreso
                <input
                  type="time"
                  name="regreso"
                  id="regreso-id"
                  value={horaRegreso}
                  onChange={(e) => setHoraRegreso(e.target.value)}
                  style={styledTimePicker}
                />
              </label>
            </StSelectHourBox>
          </Grid>
        )}
        <StContainerNewTrackGrid xs={12} smOffset={2} sm={8} mdOffset={3} md={6}>
          <StNewTrackButton
            size="large"
            fullWidth
            onClick={openModalDiff}
            endIcon={(<NewTrackIcon />)}
          >
            Registrar recorrido
          </StNewTrackButton>
        </StContainerNewTrackGrid>
      </Grid>
      <div style={cardsContainerStyle}>
        {recorridos.filter((r) => !r.terminado
          && (!r.cancelado || (r.currentCancel && r.cancelado)))
          .filter((f) => f.idUsuario === parseInt(`${id}`, 10))
          .sort((a, b) => (new Date(b.inicio) - new Date(a.inicio)))
          .map(({
            id: trackId,
            idUnidad,
            nombreRuta,
            nombreOperador,
            inicio,
            cancelado,
            currentCancel,
          }) => {
            const canceledBadge = (
              <div style={cancelBadge}>
                <span>Cancelado</span>
                <StyledButtonCloseBadge
                  variant="outlined"
                  sx={{ px: 2 }}
                  onClick={() => closeCanceledBadge(trackId)}
                  size="small"
                >
                  Cerrar
                </StyledButtonCloseBadge>
              </div>
            );
            const cancelButton = (
              <CancelStyledButton
                disabled={cancelado}
                onClick={() => cancelar(trackId)}
                color="error"
                disableElevation
                fullWidth
                startIcon={<CancelIcon />}
                size="large"
              >
                Cancelar
              </CancelStyledButton>
            );
            const cardObj = {
              regularCard: {
                buttonOrBadge: cancelButton,
                cardStyles: cardStyle,
                secondaryColor: COLORS.SLATE500,
              },
              canceledCard: {
                buttonOrBadge: canceledBadge,
                cardStyles: cancelCard,
                secondaryColor: COLORS.RED700,
              },
            };
            const cardKey = (currentCancel) ? 'canceledCard' : 'regularCard';
            const { buttonOrBadge, cardStyles, secondaryColor } = cardObj[cardKey];
            return (
              <div key={`rec-${trackId}`} style={cardStyles}>
                <div>
                  <div style={{ fontWeight: 'bold' }}>{unidades.find((u) => u.idUnidad === idUnidad)?.unidad || idUnidad}</div>
                  <div style={{ color: secondaryColor }}>{nombreRuta}</div>
                  <div style={{ color: secondaryColor }}>{nombreOperador}</div>
                  <div style={{ fontFamily: 'monospace', fontWeight: 'bold' }}>{(new Date(inicio)).toLocaleTimeString('es-ES')}</div>
                </div>
                <div>
                  {buttonOrBadge}
                </div>
              </div>
            );
          })}
      </div>
      {loading && (
        <div style={loadingContainerStyle}>
          <div className="lds-dual-ring" />
        </div>
      )}
    </StyledContainer>
  );
};

export default MainScreen;
